html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  height: 100%;
  background-color: #fafafa;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
